@import '~src/styles/partials/_variables.less';

.mat-snack-bar-container {
    &.positive .mat-simple-snackbar-action {
        color: @positiv-3;
    }

    &.negative .mat-simple-snackbar-action {
        color: @error-3;
    }
}
