html {
    .es-preset();
    height: calc(100% - 100px);

    body {
        height: 100%;
        margin: 0;
    }
}

.mat-toolbar {
    min-height: 64px;
    font-weight: 600;;
    color: @teal-500;
    background: rgba(0, 0, 0, .04);
    
    &.mat-primary {
        color: @white;
        background: @teal-500;
    }
}

.mat-step-header {
    .mat-step-label,
    .mat-step-label.mat-step-label-active {
        color: rgba(0, 0, 0, .54);
    }

    .mat-step-icon.mat-step-icon-state-edit:hover {
        border: 2px solid @teal-500;

        &::after {
            display: none;
        }
    }
}

.step-container .mat-form-field-wrapper {
    padding-bottom: 0;
}

.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-10%) !important;
}

a.mat-icon-button:hover {
    color: @teal-500;
}
