.app-container() {
  width: 100%;
  margin: 0 auto;
  
  @media screen and (min-width: 768px) {
      width: 600px;

      &:not(app-progress-bar, main) {
          width: 568px;
          padding: 0 16px;
      }
  }
}

.flex-set(@direction: row, @justify: center, @align: center, @wrap: no-wrap) {
  display: flex;
  flex-direction: @direction;
  justify-content: @justify;
  align-items: @align;
  flex-wrap: @wrap;
}

.qr-container-set(@width, @max-width, @color) {
  height: calc(@width + 6px);
  width: @width;
  max-height: calc(@max-width + 6px);
  max-width: @max-width;
  background:
      linear-gradient(to right, @color 2px, transparent 2px) 0 0,
      linear-gradient(to right, @color 2px, transparent 2px) 0 100%,
      linear-gradient(to left, @color 2px, transparent 2px) 100% 0,
      linear-gradient(to left, @color 2px, transparent 2px) 100% 100%,
      linear-gradient(to bottom, @color 2px, transparent 2px) 0 0,
      linear-gradient(to bottom, @color 2px, transparent 2px) 100% 0,
      linear-gradient(to top, @color 2px, transparent 2px) 0 100%,
      linear-gradient(to top, @color 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 15px 15px;
  padding: 6px 6px 0 6px;
}

.qr-scanner-set(@width, @max-width) {
  height: @width;
  width: @width;
  max-height: @max-width;
  max-width: @max-width;
  object-fit: contain;
}

.shadow-set {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  border-radius: 4px;
}

.link {
  color: @white;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  text-decoration: none;
  padding: 2px;
  border-radius: 4px;
}

.background-set(@size: cover, @position: center, @repeat: no-repeat, @image: null) {
  background-size: @size;
  background-position: @position;
  background-repeat: @repeat;
  background-image: @image;
}
